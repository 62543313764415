<template>
    <div>
        <h2>แก้ไขข้อมูล บริษัท / ร้านค้า</h2>
        <v-form v-model="isFormValid">
          <v-card class="mt-4">
            <div class="pa-8">
              <v-row>
                <v-col cols="12" md="6">
                  <v-img :src="imageURL" width="100%" max-height="200px" contain></v-img>
                </v-col>                
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-file-input
                    label="รูป บริษัท / ร้านค้า"
                    outlined
                    dense
                    v-model="formData.profile"
                    show-size
                    hint="ไฟล์ xls หรือ xlsx ขนาดไม่เกิน 2MB"
                    persistent-hint
                    accept=".png, .jpg, .jpeg"
                    :disabled="$store.getters['app/GET_APP_MODE'] == 'read'"
                    @change="displayUploadImage"
                  ></v-file-input>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="formData.name"
                    outlined
                    label="ชื่อ บริษัท / ร้านค้า *"                               
                    :rules="[validators.required, validators.maxLengthValidator(formData.name, 50)]"
                    hide-details="auto"          
                    dense  
                    :disabled="$store.getters['app/GET_APP_MODE'] == 'read'"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="formData.tel"
                    outlined
                    label="เบอร์"                                                   
                    hide-details="auto"          
                    dense  
                    :disabled="$store.getters['app/GET_APP_MODE'] == 'read'"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                    <v-text-field 
                        v-model="formData.address"                         
                        label="ที่อยู่ *"
                        outlined
                        dense
                        hide-details="auto"
                        :disabled="$store.getters['app/GET_APP_MODE'] == 'read'"
                        :rules="[validators.required]"
                    ></v-text-field>
                </v-col>                    
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                    <VThailandAddressAutoComplete 
                    hide-details="auto" 
                    dense v-model="formData.city" 
                    placeholder="แขวง/ตำบล *" 
                    name="city" 
                    type="district" 
                    @select="setAddress"
                    :disabled="$store.getters['app/GET_APP_MODE'] == 'read'"
                    :rules="[validators.required]"
                    />
                </v-col>
                <v-col cols="12" md="6">
                    <VThailandAddressAutoComplete 
                    hide-details="auto" 
                    dense 
                    v-model="formData.county" 
                    placeholder="เขต/อำเภอ *" 
                    name="county" 
                    type="amphoe" 
                    @select="setAddress"
                    :disabled="$store.getters['app/GET_APP_MODE'] == 'read'"
                    :rules="[validators.required]"
                    />
                </v-col>                    
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                    <VThailandAddressAutoComplete 
                    hide-details="auto" 
                    dense 
                    v-model="formData.province" 
                    placeholder="จังหวัด *" 
                    name="province" 
                    type="province" 
                    @select="setAddress"
                    :disabled="$store.getters['app/GET_APP_MODE'] == 'read'"
                    :rules="[validators.required]"
                    />
                </v-col>
                <v-col cols="12" md="6">
                    <VThailandAddressAutoComplete 
                    hide-details="auto" 
                    dense 
                    v-model="formData.postcode" 
                    placeholder="เลขไปรษณีย์ *" 
                    name="postcode" 
                    type="zipcode" 
                    @select="setAddress"
                    :disabled="$store.getters['app/GET_APP_MODE'] == 'read'"
                    :rules="[validators.required]"
                    />
                </v-col>                    
              </v-row>

              <v-divider></v-divider>

              <v-row no-gutters>
                <v-col cols="12" md="6">
                  <v-checkbox
                    v-model="formData.isTax"
                    label="เป็นกิจการที่อยู่ในระบบภาษี"
                  >
                  </v-checkbox>                  
                </v-col>
              </v-row>
              <template v-if="formData.isTax">
              <v-row no-gutters>
                <v-col cols="12" md="6">
                  <v-text-field 
                    v-model="formData.taxid"                         
                    label="เลขประจำตัวผู้เสียภาษี *"
                    outlined
                    dense
                    hide-details="auto"
                    :disabled="$store.getters['app/GET_APP_MODE'] == 'read'"
                    :rules="[validators.required]"
                  ></v-text-field>
                </v-col>                
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field 
                    v-model.number="formData.default_tax"                         
                    label="ภาษีมูลค่าเพิ่ม % *"
                    outlined
                    dense                    
                    :disabled="$store.getters['app/GET_APP_MODE'] == 'read'"
                    :rules="[validators.required, validators.minValueValidator(formData.default_tax, 0), validators.maxLengthValidator(formData.default_tax, 100) ]"
                  ></v-text-field>
                </v-col>                
              </v-row>
              </template>

              <v-divider></v-divider>

              <v-row no-gutters>
                <v-col cols="12" md="6">
                  <v-checkbox
                    v-model="formData.use_stock"
                    label="ใช้ระบบคลังสินค้า"
                  >
                  </v-checkbox>                  
                </v-col>
              </v-row>

              <template v-if="formData.use_stock">
              <v-row no-gutters>
                <v-col cols="12" md="6">
                  <v-checkbox
                    v-model="formData.negative_stock"
                    label="อนุญาติให้สินค้าคงคลังติดลบ"
                    class="ml-4"
                  >
                  </v-checkbox>                    
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" md="6">
                  <v-checkbox
                    v-model="formData.warehouse_as_pup"
                    label="ใช้ที่อยู่คลังเป็นจุดรับสินค้า"
                    class="ml-4"
                  >
                  </v-checkbox>                    
                </v-col>
              </v-row>
              </template>

              <template v-if="!formData.use_stock || !formData.warehouse_as_pup">
              <v-row no-gutters>
                <v-col cols="12" md="6">
                  <v-checkbox
                    v-model="formData.is_not_same"
                    label="สถานที่รับสินค้าเป็นคนละที่กับที่อยู่บริษัท"                    
                  >
                  </v-checkbox>           
                </v-col>
              </v-row>
              <v-row no-gutters v-if="formData.is_not_same">
                <v-col>
                  <v-card>
                    <v-card-title>ที่อยู่ในการรับสินค้า</v-card-title>

                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col>
                              <v-text-field 
                                  v-model="formData.send_address"                         
                                  label="ที่อยู่ *"
                                  outlined
                                  dense
                                  hide-details="auto"
                                  :disabled="$store.getters['app/GET_APP_MODE'] == 'read'"
                                  :rules="[validators.required]"
                              ></v-text-field>
                          </v-col>                    
                        </v-row>
                        <v-row>
                          <v-col cols="12" md="6">
                              <VThailandAddressAutoComplete 
                              hide-details="auto" 
                              dense v-model="formData.send_city" 
                              placeholder="แขวง/ตำบล *" 
                              name="city" 
                              type="district" 
                              @select="setAddress"
                              :disabled="$store.getters['app/GET_APP_MODE'] == 'read'"
                              :rules="[validators.required]"
                              />
                          </v-col>
                          <v-col cols="12" md="6">
                              <VThailandAddressAutoComplete 
                              hide-details="auto" 
                              dense 
                              v-model="formData.send_county" 
                              placeholder="เขต/อำเภอ *" 
                              name="county" 
                              type="amphoe" 
                              @select="setAddress"
                              :disabled="$store.getters['app/GET_APP_MODE'] == 'read'"
                              :rules="[validators.required]"
                              />
                          </v-col>                    
                        </v-row>
                        <v-row>
                          <v-col cols="12" md="6">
                              <VThailandAddressAutoComplete 
                              hide-details="auto" 
                              dense 
                              v-model="formData.send_province" 
                              placeholder="จังหวัด *" 
                              name="province" 
                              type="province" 
                              @select="setAddress"
                              :disabled="$store.getters['app/GET_APP_MODE'] == 'read'"
                              :rules="[validators.required]"
                              />
                          </v-col>
                          <v-col cols="12" md="6">
                              <VThailandAddressAutoComplete 
                              hide-details="auto" 
                              dense 
                              v-model="formData.send_postcode" 
                              placeholder="เลขไปรษณีย์ *" 
                              name="postcode" 
                              type="zipcode" 
                              @select="setAddress"
                              :disabled="$store.getters['app/GET_APP_MODE'] == 'read'"
                              :rules="[validators.required]"
                              />
                          </v-col>                    
                        </v-row>
                      </v-container>
                    </v-card-text>                        
                  </v-card>             
                </v-col>
              </v-row>
              </template>

              <v-divider></v-divider>

              <v-row no-gutters>
                <v-col cols="12" md="6">
                  <v-checkbox
                    v-model="formData.point_redemtion"
                    label="ใช้ระบบ CRM"
                  >
                  </v-checkbox>                  
                </v-col>
              </v-row>
              <v-row v-if="formData.point_redemtion">
                <v-col cols="12" md="6">
                <v-text-field 
                    v-model.number="formData.amount_per_point"                         
                    label="จำนวนเงินต่อ 1 แต้ม *"
                    outlined
                    dense                    
                    :disabled="$store.getters['app/GET_APP_MODE'] == 'read'"
                    :rules="[validators.required, validators.minValueValidator(formData.amount_per_point, 0) ]"
                  ></v-text-field>
                </v-col>
              </v-row>
            </div>

            <v-card-actions>              
              <v-btn
                color="primary"               
                large          
                :disabled="!isFormValid"
                :loading="sendingData"
                @click="submitForm"
                v-if="$store.getters['app/GET_APP_MODE'] != 'read'"
              >
                {{ $store.getters['app/GET_APP_MODE'] == 'create' ? "สร้าง" : "แก้ไข"}}
              </v-btn>

              <v-btn
                color="secondary"               
                outlined                 
                large
                :disabled="sendingData"
                @click="$router.go(-1)"
              >
                กลับ
              </v-btn>                
            </v-card-actions>
          </v-card>          
        </v-form>
    </div>
</template>
<script>
import { maxLengthValidator, minValueValidator, required } from '@/@core/utils/validation'
import { asyncGet, asyncUpdate } from '@/helpers/asyncAxios'
export default {
  data() {
    return {
      isFormValid: false,
      sendingData: false,
      validators: {
        required,
        maxLengthValidator,
        minValueValidator,
      },
      formData: {
        profile: null,
        taxid: null,
        name: null,
        tel: null,
        address: '',
        county: '',
        city: '',
        province: '',
        postcode: '',
        isTax: false,
        is_not_same: false,
        send_address: '',
        send_county: '',
        send_city: '',
        send_province: '',
        send_postcode: '',
        negative_stock: false,
        warehouse_as_pup: false,
        use_stock: false,
        point_redemtion: false,
        amount_per_point: 0,
        default_tax: 0,
      },
      imageURL: null,
    }
  },
  async created() {
    if (this.$store.getters['auth/GET_ABILITY'].can('update', 'Setting')) this.$store.commit('app/SET_APP_MODE', 'edit')
    else this.$store.commit('app/SET_APP_MODE', 'read')

    this.$store.commit('app/SET_GLOBAL_LOADING', true)
    try {
      const response = await asyncGet('/setting/company')

      const {
        profile,
        default_tax,
        taxid,
        use_stock,
        negative_stock,
        point_redemtion,
        address,
        send_address,
        postcode,
        send_postcode,
      } = response

      this.imageURL = profile

      this.formData = {
        ...response,
        is_not_same: address != send_address && postcode != send_postcode,
        isTax: !!default_tax && !!taxid,
        use_stock: !!use_stock,
        negative_stock: !!negative_stock,
        point_redemtion: !!point_redemtion,
      }

      this.formData.profile = null
    } catch (error) {
      this.$root.showCommonDialog('มีปัญหา', error)
    }
    this.$store.commit('app/SET_GLOBAL_LOADING', false)
  },
  methods: {
    async submitForm() {
      this.sendingData = true
      try {
        await asyncUpdate('/setting/company', this.formData)

        window.location.reload()
      } catch (error) {
        this.$root.showCommonDialog('มีปัญหา', error)
      }

      this.sendingData = false
    },
    setAddress(address) {
      this.formData.city = address.district
      this.formData.county = address.amphoe
      this.formData.province = address.province
      this.formData.postcode = address.zipcode
    },
    displayUploadImage(file) {
      if (file) {
        this.imageURL = URL.createObjectURL(file)
      }
    },
  },
  components: {},
}
</script>